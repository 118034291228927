import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {BootstrapVue} from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSocketIO from 'vue-socket.io'
import VueClipboard from 'vue-clipboard2'


const baseURLAPI = process.env.VUE_APP_API_BASE_URL
const env = process.env.VUE_APP_ENV

Vue.use(new VueSocketIO({
    debug: true,
    connection: `${baseURLAPI}/paymentpage`,
    options: {
        transports: ["websocket"]
    }
}))

axios.defaults.baseURL = baseURLAPI
Vue.prototype.$baseAPI = baseURLAPI
Vue.prototype.$env = env

Vue.use(BootstrapVue)
Vue.use(VueClipboard)
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
