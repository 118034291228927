<template>
  <div id="app">
<!--    <b-img-lazy :src="require(`@/assets/s2p/logo-s2p.png`)" v-if="showLogo" height="250px" width="250px" center alt="Logo"></b-img-lazy>-->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    urlLogo(){
      return `https://api.shop2pay.live/images/logo/logo-s2p.png`
    },
    showLogo(){
      return this.$env === 'shop2pay' ? true : false
    }
  }
}
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

#app{
  padding-bottom: 50px;
}
</style>
